import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "aws-users"
    }}>{`AWS Users`}</h1>
    <p>{`We need to get the following IAM (Identity and Access Management) users set up in the clients AWS account.
The steps to create a new user are always the same, but the attached policies differ.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Role`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bucket`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Policies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`forge`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><small>{`Deploy the frontend and backend sources`}</small></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AmazonEC2FullAccess`}</inlineCode>{` and `}<inlineCode parentName="td">{`AmazonVPCFullAccess`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`webassets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><small>{`Upload assets from Craft CMS`}</small></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webassets`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`imgix`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><small>{`Optimize CMS assets throught the imgix service`}</small></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`webassets`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom (read-only)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`db-snapshots`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><small>{`Deploy database backups from Laravel Forge`}</small></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`db-snapshots`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`✅`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`wild`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><small>{`Full access`}</small></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`AdministratorAccess`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "forge"
    }}>{`Forge`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`forge`}</inlineCode>{` user is needed for Laravel Forge to provision the server for the client. Note: this user is only required when a new server is being used. If you use an existing server in Forge (iE. `}<inlineCode parentName="p">{`wild-staging`}</inlineCode>{`), there is no need to create this user.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/users"
          }}>{`Create a new user`}</a>{` named `}<inlineCode parentName="p">{`forge`}</inlineCode>{` with `}<inlineCode parentName="p">{`Programmatic access`}</inlineCode>{` enabled.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the `}<inlineCode parentName="p">{`Attach existing policies directly`}</inlineCode>{` tab and add two permissions for `}<inlineCode parentName="p">{`AmazonEC2FullAccess`}</inlineCode>{` and `}<inlineCode parentName="p">{`AmazonVPCFullAccess`}</inlineCode>{`.
`}<img alt="Step 2" src={require("./AWS/aws-user-2.png")} />{`
`}<img alt="Step 3" src={require("./AWS/aws-user-3.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Review and save the user. Download the user credentials and save them to 1Password.
`}<img alt="Step 4" src={require("./AWS/aws-user-4.png")} /></p>
      </li>
    </ol>
    <h2 {...{
      "id": "web-assets"
    }}>{`Web Assets`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`webassets`}</inlineCode>{` user is needed for the CMS to upload assets to an S3 bucket. Note that the `}<a parentName="p" {...{
        "href": "/server-setup/1#web-assets"
      }}><inlineCode parentName="a">{`webassets`}</inlineCode>{` bucket`}</a>{` must exist and will be refered to as `}<inlineCode parentName="p">{`YOUR_BUCKET_NAME`}</inlineCode>{` in the code samples.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/policies"
          }}>{`Create a new policy`}</a>{` named `}<inlineCode parentName="p">{`webassets-read-write`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": ["s3:ListAllMyBuckets"],
      "Resource": "*"
    },
    {
      "Effect": "Allow",
      "Action": [
        "s3:GetBucketLocation",
        "s3:ListBucket",
        "s3:PutObject",
        "s3:GetObject",
        "s3:DeleteObject",
        "s3:GetObjectAcl",
        "s3:PutObjectAcl"
      ],
      "Resource": ["arn:aws:s3:::YOUR_BUCKET_NAME/*"]
    },
    {
      "Effect": "Allow",
      "Action": ["s3:GetBucketLocation", "s3:ListBucket"],
      "Resource": ["arn:aws:s3:::YOUR_BUCKET_NAME"]
    }
  ]
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/users"
          }}>{`Create a new user`}</a>{` named `}<inlineCode parentName="p">{`webassets`}</inlineCode>{` with `}<inlineCode parentName="p">{`Programmatic access`}</inlineCode>{` enabled.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Attach the newly created policy `}<inlineCode parentName="p">{`webassets-read-write`}</inlineCode>{` to the user.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Review and save the user. Download the user credentials and save them to 1Password.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "imgix"
    }}>{`imgIX`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`imgix`}</inlineCode>{` user is needed for the imgIX service to read assets from a S3 bucket. Note that the `}<a parentName="p" {...{
        "href": "/server-setup/1#web-assets"
      }}><inlineCode parentName="a">{`webassets`}</inlineCode>{` bucket`}</a>{` must exist and will be refered to as `}<inlineCode parentName="p">{`YOUR_BUCKET_NAME`}</inlineCode>{` in the code samples.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/policies"
          }}>{`Create a new policy`}</a>{` named `}<inlineCode parentName="p">{`webassets-read-only`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Sid": "VisualEditor0",
      "Effect": "Allow",
      "Action": [
         "s3:GetObject",
         "s3:ListBucket",
         "s3:GetBucketLocation"
      ],
      "Resource": [
        "arn:aws:s3:::YOUR_BUCKET_NAME",
        "arn:aws:s3:::YOUR_BUCKET_NAME/*"
      ]
    }
  ]
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/users"
          }}>{`Create a new user`}</a>{` named `}<inlineCode parentName="p">{`imgix`}</inlineCode>{` with `}<inlineCode parentName="p">{`Programmatic access`}</inlineCode>{` enabled.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Attach the newly created policy `}<inlineCode parentName="p">{`webassets-read-only`}</inlineCode>{` to the user.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Review and save the user. Download the user credentials and save them to 1Password.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "database-snapshots"
    }}>{`Database Snapshots`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`db-snapshots`}</inlineCode>{` user is needed for Froge to upload database backups to an S3 bucket. Note that the `}<a parentName="p" {...{
        "href": "/server-setup/1#database-snapshots"
      }}><inlineCode parentName="a">{`db-snapshots`}</inlineCode>{` bucket`}</a>{` must exist and will be refered to as `}<inlineCode parentName="p">{`YOUR_BUCKET_NAME`}</inlineCode>{` in the code samples.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/policies"
          }}>{`Create a new policy`}</a>{` named `}<inlineCode parentName="p">{`db-snapshots-read-write`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "Version": "2012-10-17",
  "Statement": [
    {
      "Effect": "Allow",
      "Action": [
        "s3:ListBucket",
        "s3:PutObject",
        "s3:GetObject",
        "s3:DeleteObject"
      ],
      "Resource": [
        "arn:aws:s3:::YOUR_BUCKET_NAME",
        "arn:aws:s3:::YOUR_BUCKET_NAME/*"
      ]
    }
  ]
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/users"
          }}>{`Create a new user`}</a>{` named `}<inlineCode parentName="p">{`db-snapshots`}</inlineCode>{` with `}<inlineCode parentName="p">{`Programmatic access`}</inlineCode>{` enabled.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Attach the newly created policy `}<inlineCode parentName="p">{`db-snapshots-read-write`}</inlineCode>{` to the user.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Review and save the user. Download the user credentials and save them to 1Password.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "wild"
    }}>{`Wild`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`wild`}</inlineCode>{` user is an additional admin user with full access. This account is optional, but recommened.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://console.aws.amazon.com/iamv2/home#/users"
          }}>{`Create a new user`}</a>{` named `}<inlineCode parentName="p">{`wild`}</inlineCode>{` with `}<inlineCode parentName="p">{`Programmatic access`}</inlineCode>{` enabled.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the `}<inlineCode parentName="p">{`Attach existing policies directly`}</inlineCode>{` tab and add the permission `}<inlineCode parentName="p">{`AdministratorAccess`}</inlineCode>{`.
`}<img alt="Step 2" src={require("./AWS/aws-user-7.png")} /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Review and save the user. Download the user credentials and save them to 1Password.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      